import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "ngx-one-column-layout",
  styleUrls: ["./one-column.layout.scss"],
  template: `
    <nb-layout [ngClass]="{'auctionPageActive': !isHideSidebar}">
      <nb-layout-header fixed *ngIf="isHideSidebar">
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar
        class="menu-sidebar"
        state="compacted"
        tag="menu-sidebar"
        responsive
        *ngIf="isHideSidebar"
      >
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <!-- <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>windowMode
      </nb-layout-footer> -->
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  isHideSidebar: boolean;
  constructor(private router: Router) {
    router.events.subscribe((event: any) => {
      if (event.url === "/procric/auction/bid-auction-ui") {
        this.isHideSidebar = false;
      } else {
        this.isHideSidebar = true;
      }
    });
  }
}
