import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  showMessages:any = {};
  user: any = {};
  errors : any[] = [];
  messages: any[] = [];
  submitted : boolean = false;
  constructor() { }

  ngOnInit() {
  } 

  getConfigValue(str : string){

  }

  register(){

  }

}
