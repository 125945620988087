// indian-rupees-format.directive.ts

import { Directive, ElementRef, Input, Renderer2, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[indianRupeesFormat]",
})
export class IndianRupeesFormatDirective implements OnInit {
  @Input("indianRupeesFormat") set control(control: NgControl) {
    console.log(control);
    if (control) {
      this.attachFormatting(control);
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {}

  private attachFormatting(control: NgControl): void {
    control.valueChanges.subscribe((value: number) => {
      const formattedValue = this.formatIndianRupees(value);
      this.renderer.setProperty(
        this.el.nativeElement,
        "innerText",
        formattedValue
      );
    });
  }

  private formatIndianRupees(value: number): string {
    if (value !== null && value !== undefined) {
      const formattedValue = value
        .toFixed(2)
        .replace(/\d(?=(\d{2})+\d\.)/g, "$&,");
      return `₹${formattedValue}`;
    }
    return "";
  }
}
