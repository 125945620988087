import { Component, OnInit } from "@angular/core";
import { AuthServiceService } from "../service/auth-service.service";
import { first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { NbAuthComponent, NbAuthService } from "@nebular/auth";
import { Location } from "@angular/common";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [AuthServiceService, NbAuthService],
})
export class LoginComponent implements OnInit {
  showMessages: any = {};
  user: any = {};
  submitted: boolean = false;
  loginForm: FormGroup;

  constructor(
    private authService: AuthServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  returnUrl: string;

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [
        "",
        Validators.required,
        Validators.pattern(
          /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
        ),
      ],
      password: ["", Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/procric";
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.authService
      .login(this.user)
      .pipe(first())
      .subscribe(
        (data) => {
          this.authService.verifyLoginForJavaToken();
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
