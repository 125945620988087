import { LoaderComponent } from "./components/loader/loader.component";
import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NbThemeModule,
  NbLayoutModule,
  NbButtonModule,
  NbSidebarModule,
  NbMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbToastrModule,
} from "@nebular/theme";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { AuthModule } from "./auth/auth-module";
import { ThemeModule } from "./@theme/theme.module";
import { CoreModule } from "./@core/core.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BasicAuthInterceptor } from "./_helpers/http.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { DataServiceClass } from "./service/DataService.service";
import { HttpService } from "./service/http.service";
import { Base64Service } from "./service/base64";
import {
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderConfig,
} from "ngx-ui-loader";
import { NgxUiLoaderDemoService } from "./service/ngx-loader.service";
import localeHi from "@angular/common/locales/hi";
import { registerLocaleData } from "@angular/common";
import { IndianRupeesFormatDirective } from "./_helpers/indian-rupees-format.directive";
registerLocaleData(localeHi);
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#2F5FA6",
  bgsOpacity: 0.5,
  bgsPosition: "bottom-right",
  bgsSize: 60,
  bgsType: "ball-spin-clockwise",
  blur: 5,
  delay: 0,
  fgsColor: "#2F5FA6",
  fgsPosition: "center-center",
  fgsSize: 60,
  fgsType: "three-strings",
  gap: 24,
  logoPosition: "center-center",
  logoSize: 120,
  logoUrl: "",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(40, 40, 40, 0.8)",
  pbColor: "red",
  pbDirection: "ltr",
  pbThickness: 3,
  hasProgressBar: true,
  text: "",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  maxTime: -1,
  minTime: 500,
};
@NgModule({
  declarations: [AppComponent, LoaderComponent, IndianRupeesFormatDirective],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbThemeModule.forRoot({ name: "default" }),
    NbLayoutModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NbButtonModule,
    NbEvaIconsModule,
    CoreModule.forRoot(),
  ],
  exports: [IndianRupeesFormatDirective],
  providers: [
    DataServiceClass,
    HttpService,
    Base64Service,
    NgxUiLoaderDemoService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "hi_IN" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
