<!-- <h1 id="title" class="title">Login</h1> -->
<img src="assets/images/Procric8.png" alt="Procric8" class="mx-auto d-block" width="230">
<p class="sub-title">Hello! Log in with your credentials.</p>

<!-- <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert> -->

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Email address:</label>
    <input nbInput fullWidth [(ngModel)]="user.username" #email="ngModel" name="email" id="input-email"
      placeholder="Email address" fieldSize="large" autofocus
      [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''" [required]="true"
      [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        Email is required!
      </p>
    </ng-container>
    <ng-container *ngIf="f.username.errors.pattern">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        Please enter valid email address or phone number
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Password:</label>
      <!-- <a class="forgot-password caption-2" routerLink="../request-password">Forgot Password?</a> -->
    </span>
    <input nbInput fullWidth [(ngModel)]="user.password" #password="ngModel" name="password" type="password"
      id="input-password" placeholder="Password" fieldSize="large"
      [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" [required]="true" [minlength]="4"
      [maxlength]="20" [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Password is required!
      </p>
      <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        Password should contain from 4 to 20 characters
      </p>
    </ng-container>
  </div>

  <!-- <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
  </div> -->

  <button nbButton fullWidth status="primary" size="large" [class.btn-pulse]="submitted">
    Log In
  </button>
</form>

<!-- <section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
  or enter with:
  <div class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a *ngIf="socialLink.link"
         [routerLink]="socialLink.link"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
      <a *ngIf="socialLink.url"
         [attr.href]="socialLink.url"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
    </ng-container>
  </div>
</section> -->

<section class="another-action" aria-label="Register">
  Don't have an account? <a class="text-link" routerLink="../register">Register</a>
</section>