import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
@Injectable()
export class DataServiceClass {
  private _auctionDetail: Object = null;
  private _lastBidTeamId: any = null;
  private _currentBidPlayer: any = null;
  private _tournamentDetail: any = null;
  private _teamData: any = null;
  private _groupData: any = null;
  private _lastTimeInvitee: any = null;
  private _lastTimeTeamPlayer: any = null;
  private _inviteePlayerListMap: Object = {};
  private _allTeamPlayerListMap: Object = {};
  private _returnToAuctionFlag: boolean = true;
  private onDestroy$ = new Subject<boolean>();
  constructor() {
    this.returnToAuctionFlagSet = false;
    this.auctionDetailObj();
    this.lastBidTeamIdObj();
    this.currentBidPlayerObj();
    this.tournamentDetailObj();
    this.teamDataInit();
    this.groupDataInit();
  }
  //Invitee playerlist map
  public get inviteePlayerListMapGet(): any {
    return this._inviteePlayerListMap;
  }
  public set inviteePlayerListMapSet(value: any) {
    this._inviteePlayerListMap = value;
  }
  //All team player map
  public get allTeamPlayerListMapGet(): any {
    return this._allTeamPlayerListMap;
  }
  public set allTeamPlayerListMapSet(value: any) {
    this._allTeamPlayerListMap = value;
  }
  //Last time var for invitee
  public get lastTimeInviteeGet(): any {
    return this._lastTimeInvitee;
  }
  public set lastTimeInviteeSet(value: any) {
    this._lastTimeInvitee = value;
  }
  //Last time var for Team player
  public get lastTimeTeamPlayerGet(): any {
    return this._lastTimeTeamPlayer;
  }
  public set lastTimeTeamPlayerSet(value: any) {
    this._lastTimeTeamPlayer = value;
  }
  //Flag var to hide and show auction ui
  public get returnToAuctionFlagGet(): boolean {
    return this._returnToAuctionFlag;
  }
  public set returnToAuctionFlagSet(value: boolean) {
    this._returnToAuctionFlag = value;
  }
  //Team data
  public get teamDataGet(): any {
    return this._teamData;
  }
  public set teamDataSet(value: any) {
    this._teamData = value;
    this.setLocal("Admin", "teamData", value);
  }
  //Group data
  public get groupDataGet(): any {
    return this._groupData;
  }
  public set groupDataSet(value: any) {
    this._groupData = value;
    this.setLocal("Admin", "groupData", value);
  }
  //Auction data
  public get auctionDetailGet(): any {
    return this._auctionDetail;
  }
  public set auctionDetailSet(value: any) {
    this._auctionDetail = value;
    this.setLocal("Admin", "auctionObj", value);
  }
  public get lastBidTeamIdGet(): any {
    return this._lastBidTeamId;
  }
  public set lastBidTeamIdSet(value: any) {
    this._lastBidTeamId = value;
    this.setLocal("Admin", "lastBidTeamId", value);
  }
  public get currentBidPlayerGet(): any {
    return this._currentBidPlayer;
  }
  public set currentBidPlayerSet(value: any) {
    this._currentBidPlayer = value;
    this.setLocal("Admin", "currentBidPlayer", value);
  }
  //Tournament data
  public get tournamentDetailGet(): any {
    return this._tournamentDetail;
  }
  public set tournamentDetailSet(value: any) {
    this._tournamentDetail = value;
    this.setLocal("Admin", "tourObj", value);
  }
  //Init Auction data
  private auctionDetailObj() {
    let auctionObj = this.getLocal("Admin", "auctionObj");
    if (auctionObj != undefined) {
      this._auctionDetail = auctionObj;
    } else {
      this._auctionDetail = null;
    }
  }
  //Init Last teamId Data
  private lastBidTeamIdObj() {
    let lastBidTeamId = this.getLocal("Admin", "lastBidTeamId");
    if (lastBidTeamId != undefined) {
      this._lastBidTeamId = lastBidTeamId;
    } else {
      this._lastBidTeamId = null;
    }
  }
  //Init Current Bid Player
  private currentBidPlayerObj() {
    let player = this.getLocal("Admin", "currentBidPlayer");
    if (player != undefined) {
      this._currentBidPlayer = player;
    } else {
      this._currentBidPlayer = null;
    }
  }
  //Init Group data
  private groupDataInit() {
    let group = this.getLocal("Admin", "groupData");
    if (group != undefined) {
      this._groupData = group;
    } else {
      this._groupData = null;
    }
  }
  //Init Tournament data
  private tournamentDetailObj() {
    let tourObj = this.getLocal("Admin", "tourObj");
    if (tourObj != undefined) {
      this._tournamentDetail = tourObj;
    } else {
      this._tournamentDetail = null;
    }
  }
  //Init Team data
  private teamDataInit() {
    let teamObj = this.getLocal("Admin", "teamData");
    if (teamObj != undefined) {
      this._teamData = teamObj;
    } else {
      this._teamData = null;
    }
  }
  //clear DataService
  clearDataService() {
    this._tournamentDetail = null;
    this._auctionDetail = null;
    this._teamData = null;
    this._allTeamPlayerListMap = null;
    this._inviteePlayerListMap = null;
    this._lastTimeInvitee = null;
    this._lastTimeTeamPlayer = null;
    this._groupData = null;
    this._groupData = null;
    this._lastBidTeamId = null;
    this._currentBidPlayer = null;
  }
  //Set module to localstorage
  setLocal(module: string, key: string, data: any) {
    // this.removeLocal(module , key);
    let modObj = JSON.parse(localStorage.getItem(module));
    if (modObj == undefined) {
      modObj = {};
    }
    modObj[key] = data;
    localStorage.setItem(module, JSON.stringify(modObj));
  }
  //Get module from localstorage
  getLocal(module: string, key: string) {
    let modObj = JSON.parse(localStorage.getItem(module));
    if (modObj == undefined) {
      modObj = {};
      localStorage.setItem(module, JSON.stringify(modObj));
    }
    if (key != undefined) return modObj[key];
    return modObj;
  }
  //Remove module from localstorage
  removeLocal(module: string, key: string) {
    if (key != undefined) {
      let modObj = JSON.parse(localStorage.getItem(module));
      if (modObj != undefined) {
        delete modObj[key];
        localStorage.setItem(module, JSON.stringify(modObj));
        return;
      }
    }
    localStorage.removeItem(module);
  }
  /**
   * Remove [null,string,undefined] key from given Object
   * @param  {any} reqObj
   */
  public filter(reqObj: any) {
    Object.keys(reqObj).forEach(function (key) {
      if (
        reqObj[key] == null ||
        reqObj[key] == "string" ||
        reqObj[key] == "undefined" ||
        reqObj[key] == undefined
      ) {
        delete reqObj[key];
      }
    });
    return reqObj;
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
