import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Base64Service {
    private _arrayOfBase64Image: any;
    private rawBase: any;

    constructor() {
        this.getarrayOfBase64Image();
        this.getRawBase();
    }

    public getarrayOfBase64Image(): any {
        return this._arrayOfBase64Image;
    }
    public setarrayOfBase64Image(value: any) {
        this._arrayOfBase64Image = value;
    }

    GetMimetype(RawBase64) {
        this.setRawBase(RawBase64);
        console.log(RawBase64);
        let image_arr = RawBase64.split(",");
        // console.log([image_arr[0].substring("data:image/".length, image_arr[0].indexOf(";base64")), image_arr[1]]);
        return image_arr[0].split(";")[0].split("/")[1];
    }

    MakeBase64(event) {
        // let fileList: FileList = event.target.files;
        // const file: File = fileList[0];
        this.handleInputChange(event);
    }

    handleInputChange(files) {
        const file = files;
        // var pattern = /image-*/;
        const reader = new FileReader();
        // if (!file.type.match(pattern)) {
        //     alert('invalid format');
        //     return;
        // }
        reader.onloadend = this._handleReaderLoaded.bind(this);
         reader.readAsDataURL(file);
    }

    _handleReaderLoaded(e) {
        let reader = e.target;
        let base64result = reader.result.substr(reader.result.indexOf(',') + 1);
        let mime = this.GetMimetype(reader.result);
        let arrayofimage = {
            "mime": mime,
            "string": base64result
        };
        this.setarrayOfBase64Image(arrayofimage);
        return arrayofimage;
    }

    public getRawBase(): any {
        return this.rawBase;
    }

    public setRawBase(value: any) {
        this.rawBase = value;
    }
}
