import { environment } from "src/environments/environment";
export default class Url {
  static imageUrl: string = environment.Image_Base_Url;
  //Auth Api
  static Login_URL: string = `${
    environment.Api_Base_Url + `${"v2/auth/admin"}`
  }`;
  static Java_Login_URL: string = `${
    environment.Java_Api_Base_Url + `${"v1/auth/login"}`
  }`;
  static Java_Login_Verify_URL: string = `${
    environment.Java_Api_Base_Url + `${"v1/auth/identity/{identity}/verify"}`
  }`;
  static User_Profile_URL: string = `${
    environment.Api_Base_Url + `${"users/profile"}`
  }`;
  //Tournament Api
  static Tournament_list__URL: string = `${
    environment.Api_Base_Url + `${"v2/tournament/list"}`
  }`;
  //Auction Api
  static Auction_list_URL: string = `${
    environment.Api_Base_Url + `${"v2/auction/{tour_id}/list"}`
  }`;
  static New_Auction_list_URL: string = `${
    environment.Java_Api_Base_Url + `${"v1/tour/{tId}/auction"}`
  }`;
  static Auction_Add_Post_URL: string = `${
    environment.Api_Base_Url + `${"v2/auction/{tour_id}/create"}`
  }`;
  static New_Auction_Add_Post_URL: string = `${
    environment.Java_Api_Base_Url + `${"v1/tour/{tId}/auction"}`
  }`;
  static Auction_Delete_Post_URL: string = `${
    environment.Api_Base_Url + `${"v2/auction/{tour_id}/delete/{auction_id}"}`
  }`;
  static New_Auction_Delete_Post_URL: string = `${
    environment.Java_Api_Base_Url + `${"v1/tour/{tourId}/auction/{aId}"}`
  }`;
  static Auction_Reset_Post_URL: string = `${
    environment.Api_Base_Url + `${"v2/auction/{tour_id}/reset/{auction_id}"}`
  }`;
  static Auction_Tournament_list: string = `${
    environment.Api_Base_Url + `${"v2/auction/tournament/list"}`
  }`;
  static Auction_Active_Team_State_Post_Url: string = `${
    environment.Api_Base_Url +
    `${"v2/auction/{tour_id}/suffle/{auction_id}/active"}`
  }`;
  static Auction_Owner_Invitee_List: string = `${
    environment.Api_Base_Url + `${"v2/auction/{tour_id}/all/invitees"}`
  }`;
  static Auction_Team_Player_Post_Add: string = `${
    environment.Api_Base_Url +
    `${"v2/auction/{tour_id}/team/{team_id}/players/{player_id}"}`
  }`;
  static Auction_Team_Player_Post_Delete: string = `${
    environment.Java_Api_Base_Url +
    `${"v1/tour/{tour_id}/auction/{aId}/players/{player_id}"}`
  }`;
  static Auction_Team_Player_Delete_Add: string = `${
    environment.Api_Base_Url +
    `${"v2/auction/{tour_id}/team/{team_id}/players/{player_id}"}`
  }`;
  //Invite Api
  static Invite_list: string = `${
    environment.Api_Base_Url + `${"v2/auction/tournament/list"}`
  }`;
  //Shuffle Api
  static Auction_Shuffle_State_Get_Url: string = `${
    environment.Api_Base_Url + `${"v2/auction/{tour_id}/suffle/{auction_id}"}`
  }`;
  static Bid_Auction_Shuffle_State_Get_Url: string = `${
    environment.Java_Api_Base_Url +
    `${"v1/tour/{tId}/auction/{auctionId}/teams"}`
  }`;
  //Tournament Player
  static Tournament_Player_Get_List: string = `${
    environment.Api_Base_Url + `${"v2/auction/{tour_id}/players/{auction_id}"}`
  }`;
  static Tournament_Team_Player_Get_List: string = `${
    environment.Api_Base_Url +
    `${"v2/auction/{tour_id}/team/{team_id}/players"}`
  }`;
  //Group Crud
  static Group_Get_List: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/group/list"}`
  }`;
  static Group_Add_Post: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/group/create"}`
  }`;
  static Group_Update_Post: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/group/{id}/update"}`
  }`;
  static Group_Delete: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/group/{id}/delete"}`
  }`;
  //Story Crud
  static Story_List_Get: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/story/list"}`
  }`;
  static Story_Add_Post: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/story/create"}`
  }`;
  static Story_Update_Put: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/story/{id}/update"}`
  }`;
  static Story_Delete: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/group/{id}/delete"}`
  }`;
  //Fixture Crud
  static Fixture_List_Get: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/fixture/list"}`
  }`;
  static Fixture_Add_Post: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/fixture"}`
  }`;
  static Fixture_Update_Post: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/fixture/{id}"}`
  }`;
  static Fixture_Delete: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/fixture/{id}"}`
  }`;
  //Team Crud
  static Organization_Wise_Tournament_Team_List_Get: string = `${
    environment.Java_Api_Base_Url + `${"v1/org/{org_id}/team"}`
  }`;
  static Tournament_Team_Save: string = `${
    environment.Java_Api_Base_Url + `${"v1/tour/{tId}/teams"}`
  }`;
  static Tournament_Team_Delete: string = `${
    environment.Java_Api_Base_Url + `${"v1/tour/{tId}/teams/{teamId}"}`
  }`;
  static Organization_Wise_Tournament_Team_Update_Delete: string = `${
    environment.Java_Api_Base_Url + `${"v1/org/{org_id}/team/{teamId}"}`
  }`;
  static Tournament_Team_List_Get: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/team/list"}`
  }`;
  static Tournament_Organization_Team_List_Get: string = `${
    environment.Java_Api_Base_Url + `${"v1/org"}`
  }`;
  static Team_Owner_Update_Patch: string = `${
    environment.Api_Base_Url +
    `${"v2/tournament/{tour_id}/team/{team_id}/owner/{owner_id}"}`
  }`;
  static Team_Group_Update_Patch: string = `${
    environment.Api_Base_Url +
    `${"v2/tournament/{tour_id}/team/{team_id}/group/{group_name}"}`
  }`;
  static Team_Captain_Update_Post: string = `${
    environment.Api_Base_Url +
    `${"v2/tournament/{tour_id}/team/{team_id}/player/{player_id}"}`
  }`;
  //List of all tournament player
  static List_Of_Tournament_Player: string = `${
    environment.Api_Base_Url + `${"v2/tournament/{tour_id}/player/list"}`
  }`;
  static Tournament_Player_Delete: string = `${
    environment.Java_Api_Base_Url + `${"v1/tour/{tId}/player/{pId}"}`
  }`;
  static List_Of_Tournament_Player_NEW: string = `${
    environment.Java_Api_Base_Url + `${"v1/t/{tour_id}/players"}`
  }`;
  static Search_Player: string = `${
    environment.Java_Api_Base_Url + `${"v1/player/search"}`
  }`;
  static Upload_Tournament_Player: string = `${
    environment.Java_Api_Base_Url + `${"v1/t/{tour_id}/upload/players"}`
  }`;
  static Invite_Player_To_Tournament: string = `${
    environment.Java_Api_Base_Url + `${"v1/t/{tour_id}/invite/players"}`
  }`;
  static Add_New_Player_To_Tournament: string = `${
    environment.Java_Api_Base_Url + `${"v1/tour/{tour_id}/players"}`
  }`;
  static Resend_Invite_Link_To_Tournament_Player: string = `${
    environment.Java_Api_Base_Url + `${"v1/t/{tour_id}/invite/{id}/resend"}`
  }`;
  //Tournament team player crud
  static Delete_Player_In_Team: string = `${
    environment.Api_Base_Url +
    `${"v2/tournament/{tour_id}/team/{team_id}/player/{player_id}"}`
  }`;
  static Add_Player_In_Team: string = `${
    environment.Api_Base_Url +
    `${"v2/tournament/{tour_id}/team/{team_id}/player/{player_id}"}`
  }`;
  static Update_Player_In_Team_Post: string = `${
    environment.Api_Base_Url +
    `${"v2/tournament/{tour_id}/team/{team_id}/player/{player_id}"}`
  }`;
  static Tournament_Team_Player_List: string = `${
    environment.Api_Base_Url +
    `${"v2/tournament/{tour_id}/team/{team_id}/player/list"}`
  }`;
  // New Auctions
  static Auction_Player_Based_On_Bid_Type: string = `${
    environment.Java_Api_Base_Url +
    `${"v1/tour/{tId}/auction/{auctionId}/{bidType}"}`
  }`;
  static Last_Bid_Price: string = `${
    environment.Java_Api_Base_Url +
    `${"v1/tour/{tId}/auction/{auctionId}/last/bid"}`
  }`;
  static New_Auction_Players: string = `${
    environment.Java_Api_Base_Url +
    `${"v1/tour/{tId}/auction/{auctionId}/players"}`
  }`;
  static New_Auction_Reset_Post_URL: string = `${
    environment.Java_Api_Base_Url +
    `${"v1/tour/{tId}/auction/{auctionId}/reset"}`
  }`;
  static Undo_Auction_Delete_URL: string = `${
    environment.Java_Api_Base_Url +
    `${"v1/tour/{tId}/auction/{auctionId}/undo"}`
  }`;
  static Auction_Team_Configuration_Patch_URL: string = `${
    environment.Java_Api_Base_Url +
    `${"v1/tour/{tId}/team/{teamId}/auction/{aId}"}`
  }`;
  static Auction_Team_Group_Configuration_Patch_URL: string = `${
    environment.Java_Api_Base_Url + `${"v1/tour/{tId}/teams/{teamId}"}`
  }`;
  static Auction_Player_Configuration_Patch_URL: string = `${
    environment.Java_Api_Base_Url + `${"v1/tour/{tId}/players/{pId}"}`
  }`;
}
// {{host_url}}auction/74/all/invitees
