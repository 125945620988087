import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthServiceService } from "../auth/service/auth-service.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { map } from "rxjs/operators";
@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthServiceService,
    private ngxUiLoaderService: NgxUiLoaderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    const currentUser = this.authenticationService.currentUserValue;
    const currentJavaTokenValue =
      this.authenticationService.currentJavaTokenValue;
    if (currentUser && currentUser?.data?.token?.access_token != null) {
      let headers = {};
      if (
        currentJavaTokenValue &&
        request.url.includes(environment.Java_Api_Base_Url)
      ) {
        headers = {
          Authorization: `Bearer ${currentJavaTokenValue}`,
        };
      } else {
        headers = {
          Authorization: `Bearer ${currentUser.data.token.access_token}`,
        };
      }
      request = request.clone({
        setHeaders: headers,
      });
    }
    if (
      !request.headers.has("Content-Type") &&
      !request.url.includes("/upload")
    ) {
      request = request.clone({
        headers: request.headers.set("Content-Type", "application/json"),
      });
    }
    this.ngxUiLoaderService.startLoader("master", "fg-default");
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.ngxUiLoaderService.stopLoader("master", "fg-default");
          if (event.body && event.body.success) {
            // this.toastr.successToastr(event.body.success,event.body.message);
          }
        }
        return event;
      })
    );
  }
}
