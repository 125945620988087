import { DataServiceClass } from "src/app/service/DataService.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { HttpService } from "../../service/http.service";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import Url from "src/app/_helpers/Url";

@Injectable({
  providedIn: "root",
})
export class AuthServiceService {
  private currentUserSubject: BehaviorSubject<any>;
  private javaTokenSubject: BehaviorSubject<string>;
  public currentUser: Observable<any>;
  public currentJavaToken: Observable<any>;
  returnUrl: any;

  constructor(
    private http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private dataServiceClass: DataServiceClass
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.javaTokenSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("javaToken"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentJavaToken = this.javaTokenSubject.asObservable();
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/procric";
  }

  public get currentUserValue(): any {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    return this.currentUserSubject.value;
  }

  public get currentJavaTokenValue(): any {
    this.javaTokenSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("javaToken"))
    );
    this.currentJavaToken = this.javaTokenSubject.asObservable();
    return this.javaTokenSubject.value;
  }

  login(data) {
    return this.http.postData(data, `${Url.Login_URL}`).pipe(
      map((user) => {
        localStorage.setItem("currentUser", JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  loginForJavaToken(data: any) {
    return this.http.postData(data, `${Url.Java_Login_URL}`).pipe(
      map((user) => {
        localStorage.setItem("javaToken", JSON.stringify(user.data.token));
        this.javaTokenSubject.next(user.data.token);
        return user;
      })
    );
  }

   verifyLoginForJavaToken() {
    const url = Url.Java_Login_Verify_URL.replace("{identity}", "1111111111");
    return this.http.postData({}, url).subscribe((response: any) => {
      let data = {};
      if (response.data && response.data.validIdentity) {
        data["uuid"] = response.data.uuid;
        data["password"] = "1234";
        this.loginForJavaToken(data).subscribe((data: any) => {
          console.log(data)
          this.router.navigate([this.returnUrl]);
        });
      }
    });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.javaTokenSubject.next(null);
    this.dataServiceClass.clearDataService();
    this.router.navigate(["/auth/login"]);
  }
}
