import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil, filter } from "rxjs/operators";
import { Subject } from "rxjs";
import { AuthServiceService } from "src/app/auth/service/auth-service.service";
import { DataServiceClass } from "src/app/service/DataService.service";
import Url from "src/app/_helpers/Url";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  currentTheme = "default";
  userMenu: any = [
    { title: "Profile", fun: "myProfile" },
    { title: "Log out", fun: "logOut" },
  ];
  imageUrl: string = Url.imageUrl;
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthServiceService,
    public dataServiceClass: DataServiceClass
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.user = this.authService.currentUserValue.data.user;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        this.currentTheme = themeName;
      });
    this.menuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === "headerMenu"))
      .subscribe((title) => {
        this[title.item["fun"]]();
      });
    
  }

  logOut() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  menuClick(e) {
    console.log(e);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome("dashboard");
    return false;
  }
}
